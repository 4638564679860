import TranslationPT from "../../translations/pt.json"
import TranslationEN from "../../translations/en.json"

const translations = {
  en: TranslationEN,
  pt: TranslationPT,
}

const getNestedKeys = function(object, path) {
  function index(obj, i) {
    return obj[i]
  }
  return path.split(".").reduce(index, object)
}

const useTranslation = (lang, context) => {
  const chosenLang = lang || "pt"
  const text = translations[chosenLang] || {}

  return key => {
    if (context)
      return (text[context] && getNestedKeys(text[context], key)) || ""
    return getNestedKeys(text, key) || ""
  }
}

export { useTranslation }
