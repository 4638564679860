import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import logo from "../../../static/images/logo.png"

const CustomLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    width: 100px;
    margin-right: 20px;
  }
`;

const Logo = ({ siteTitle }) => (
  <CustomLogo>
    <Link to="/" title={siteTitle}>
      <img alt={"Logo"} src={logo} />
    </Link>
  </CustomLogo>
)

Logo.propTypes = {
  siteTitle: PropTypes.string,
}

Logo.defaultProps = {
  siteTitle: ``,
}

export default Logo