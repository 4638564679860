import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";

import { primaryColor } from "../../colors"

const Link = (props) => {

  let to = props.to;
  if (props.lang === "en") {
    const locatedLink = `/en${props.to}`
    to = locatedLink
  }

  return (
    <AniLink
      {...props}
      cover
      direction="right"
      duration={0.8}
      bg={primaryColor}
      to={to}
    />
  )
}

export default Link
