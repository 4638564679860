import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Link from "../atoms/link"

import Logo from "../molecules/logo"
import LanguagePicker from "../molecules/languagePicker"

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import { useTranslation } from "../../utils/translate"

const ContainerHeader = styled.header`
  background-color: #ffffff;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 1000;

  -webkit-box-shadow: 0px -4px 9px 8px rgba(0,0,0,0.35);
  -moz-box-shadow: 0px -4px 9px 8px rgba(0,0,0,0.35);
  box-shadow: 0px -4px 9px 8px rgba(0,0,0,0.35);
`;

const CustomHeader = styled.header`
  max-width: 1024px;
  margin: 0 auto;
`;

const MarginPlaceholder = styled.div`
  height: 80px;
`

const NavLink = ({ to, children, lang }) => (
  <Nav.Link as={Link} to={to} lang={lang} activeClassName="active">{children}</Nav.Link>
)

const Header = ({ siteTitle, lang, location }) => {

  const t = useTranslation(lang, "header")

  return (
    <>
      <ContainerHeader className="fixed-top">
        <CustomHeader>
          <Navbar expand="sm" variant="light">
            <Navbar.Brand to="/" class="py-0">
              <Logo siteTitle={siteTitle} lang={lang} />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
              <Nav className="ml-auto">
                <NavLink to="/" lang={lang}>{t("home")}</NavLink>
                <NavLink to="/equipe" lang={lang}>{t("team")}</NavLink>
                <NavLink to="/contato" lang={lang}>{t("contact")}</NavLink>
                <LanguagePicker location={location} lang={lang} />
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </CustomHeader>
      </ContainerHeader>
      <MarginPlaceholder></MarginPlaceholder>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
