import React from "react"
import { Link } from "gatsby"

import Nav from 'react-bootstrap/Nav'

import FlagBR from "../../../static/images/flags/br.svg"
import FlagUK from "../../../static/images/flags/uk.svg"

const LanguagePicker = ({ location, lang }) => {

  if (!location || !lang) return (<></>)

  const originalPath = location.pathname
  let finalPath;

  let flag, flagName;
  if (lang === "pt") {
    flag = FlagUK
    flagName = "EN"
    finalPath = `/en${originalPath}`
  }
  if (lang === "en") {
    flag = FlagBR
    flagName = "PT"
    finalPath = originalPath.substring(3);
  }

  return (
    <Nav.Link as={Link} to={finalPath} className="ml-2">
      <img src={flag} width="20px" height="20px" alt="Flag" />
      <span className="pl-1">{flagName}</span>
    </Nav.Link>
  )
}

export default LanguagePicker