import Link from "../atoms/link"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import Logo from "../molecules/logoText"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { useTranslation } from "../../utils/translate"

const CustomFooter = styled.footer`
  padding: 2rem 1rem 45px;
  text-align: left;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
`

const LinkStyled = styled(Link)`
  margin-bottom: 1rem;
`

const year = new Date().getFullYear();

const Footer = ({ company, lang }) => {

  const t = useTranslation(lang, "footer")

  return (
    <CustomFooter>
      <Container fluid>
        <Row>
          <Col xs="12" md="7" lg="9" className="mb-2">
            <Row className="mb-3"><Logo siteTitle={company} lang={lang} /></Row>
            <Row>
              <Col className="pl-0">
                <p>Rua Professor Magalhães Penido, 303. Bairro São Luiz. CEP 31270-383.</p>
                <p>Belo Horizonte - MG. Brasil.</p>
              </Col>
            </Row>
          </Col>
          <Col xs="12" md="5" lg="3" className="mb-2">
            <Row><h4>{t("quickLinks")}</h4></Row>
            <Row><LinkStyled to="/" lang={lang}>{t("home")}</LinkStyled></Row>
            <Row><LinkStyled to="/equipe" lang={lang}>{t("team")}</LinkStyled></Row>
            <Row><LinkStyled to="/contato" lang={lang}>{t("contact")}</LinkStyled></Row>
          </Col>
        </Row>
      </Container>

      <p style={{textAlign: "center"}} className="mt-5">
        Copyright {year}, <Link to="/" lang={lang}>{company}</Link>.
        {t("rights")}.
      </p>
    </CustomFooter>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
