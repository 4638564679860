import Link from "../atoms/link"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const CustomLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    width: 60px;
    margin-right: 20px;
  }
`;

const BrandTitle = styled.span`
  font-size: 1.4rem;
  letter-spacing: 0.2em;
  color: #FA6464;
  opacity: 0.9;
  
  .brand-red {
    color: #707070;
  }
`

const Logo = ({ siteTitle, lang }) => (
  <CustomLogo>
    <Link to="/" title={siteTitle} lang={lang}>
      <BrandTitle>d20<span className="brand-red">LABS</span></BrandTitle>
    </Link>
  </CustomLogo>
)

Logo.propTypes = {
  siteTitle: PropTypes.string,
}

Logo.defaultProps = {
  siteTitle: ``,
}

export default Logo
